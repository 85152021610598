
.flex-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  
  .flex-last {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  
  
  .input-group-addon, .input-group-btn, .input-group .form-control {
    -js-display: flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .input-group-addon, .input-group-btn {
    white-space: nowrap;
    vertical-align: middle;
  }
  .input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
  }
  
  .input-group-btn>.btn {
    position: relative;
    flex: 1;
  }
  
  .pull-0{
    right:auto
  }
  .pull-1{
    right:8.33333%
  }
  .pull-2{
    right:16.66667%
  }
  .pull-3{
    right:25%
  }
  .pull-4{
    right:33.33333%
  }
  .pull-5{
    right:41.66667%
  }
  .pull-6{
    right:50%
  }
  .pull-7{
    right:58.33333%
  }
  .pull-8{
    right:66.66667%
  }
  .pull-9{
    right:75%
  }
  .pull-10{
    right:83.33333%
  }
  .pull-11{
    right:91.66667%
  }
  .pull-12{
    right:100%
  }
  .push-0{
    left:auto
  }
  .push-1{
    left:8.33333%
  }
  .push-2{
    left:16.66667%
  }
  .push-3{
    left:25%
  }
  .push-4{
    left:33.33333%
  }
  .push-5{
    left:41.66667%
  }
  .push-6{
    left:50%
  }
  .push-7{
    left:58.33333%
  }
  .push-8{
    left:66.66667%
  }
  .push-9{
    left:75%
  }
  .push-10{
    left:83.33333%
  }
  .push-11{
    left:91.66667%
  }
  .push-12{
    left:100%
  }
  .offset-1{
    margin-left:8.33333%
  }
  .offset-2{
    margin-left:16.66667%
  }
  .offset-3{
    margin-left:25%
  }
  .offset-4{
    margin-left:33.33333%
  }
  .offset-5{
    margin-left:41.66667%
  }
  .offset-6{
    margin-left:50%
  }
  .offset-7{
    margin-left:58.33333%
  }
  .offset-8{
    margin-left:66.66667%
  }
  .offset-9{
    margin-left:75%
  }
  .offset-10{
    margin-left:83.33333%
  }
  .offset-11{
    margin-left:91.66667%
  }

  .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
    box-shadow: none;
  }
  
  .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus{
    box-shadow: none;
  }
  