#stores {
  .page-stores {
    .store-item {
      border-bottom: 1px solid $gray-light;
      &:last-child{border-bottom: none; }
    }
    .store-picture {
      img {
        max-width: 100%;
      }
    }
    .store-item-container {
      @include display(flex);
      @include justify-content(space-around);
      @include align-items(center);
      padding: $large-space 0;
      ul {
        margin-bottom: 0;
        font-size: $font-size-lg;
      }
      .divide-left {
        border-left: $gray-light 1px solid;
        tr {
          height: rem(25px);
        }
        td {
          padding-left: rem(6px);
        }
        th {
          text-align: right;
        }
      }
      .store-description{
        font-size: $base-font-size;
      }
    }
    .store-item-footer {
      margin-top: rem(8px);
      padding-top: rem(8px);
      @include display(flex);
      @include justify-content(space-around);
      &.divide-top {
        border-top: $gray-light 1px solid;
      }
      div:first-child {
        @include flex(0 0 65%);
      }
      i.material-icons{
        margin-right: $small-space;
        font-size: $base-font-size;
      }
      li{
        margin-bottom: $small-space;
      }
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(sm) {
  #stores {
    .page-stores {
      width: 100%;
      .store-item-container {
        padding: rem(16px) 0;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  #stores {
    .page-stores {
      .store-item-container {
        display: block;
        .divide-left {
          border-left: none;
        }
        .store-description {
          a {
            margin-bottom: rem(8px);
          }
          address {
            margin-bottom: rem(8px);
          }
        }
      }
      .store-item-footer {
        display: block;
        &.divide-top {
          border-top: $gray-light 1px solid;
        }
        li{
          margin-bottom: $small-space;
        }
        .card-block {
          padding: rem(12px) rem(12px) 0;
        }
      }
    }
  }
}
